import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import VideoEmbed from "../../components/SingleTemplateComponents/VideoEmbed"
import EntryHeader from "../../components/SingleTemplateComponents/EntryHeader"
import ShareBar from "../../components/SingleTemplateComponents/ShareBar"

const SermonClip = ( { data: { sermonClip } } ) => {

	return (
		<Layout>
			<Seo
				title={sermonClip.title}
				canonical={sermonClip.seo?.canonical}
				uri={sermonClip.uri}
				socialImage={sermonClip.featuredImage?.node}
				description={sermonClip.seo?.metaDesc ? sermonClip.seo.metaDesc : sermonClip.excerpt.replace(/(<([^>]+)>)/gi, "") }
			/>
			
			<VideoEmbed video={sermonClip.sermonMeta.video} />

			<EntryHeader
				title={sermonClip.title}
				date={sermonClip.date}
				icon={sermonClip?.organizations?.nodes[0]?.organizationMeta?.logo?.localFile}
				format='Sermon Clip'
			/>

			<ShareBar 
				url={sermonClip.uri}
			/>
		
			<div
				className="entry-content max-w-2xl px-4 mx-auto mt-4 md:-mt-64 min-h-[40vh]"
				dangerouslySetInnerHTML={{ __html: sermonClip.content }}
			/>
				
		</Layout>
	);
}

export const sermonClipQuery = graphql`
	query SermonClipById(
		$id: String!
	) {
		sermonClip: wpSermonClip( id: { eq: $id } ) {
			... SermonClipContent
		}
	}
`
export default SermonClip